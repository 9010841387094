.reserveForm {
	text-align: left;
}

.DateTime {
	&__Container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		position: relative;
	}

	&__DateTimeInputBox {
		flex: 0.49;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	&__PartySizeInputBox {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		margin-bottom: 3vh;
		width: 100%;
	}
}

.TablesInputBox button {
	min-width: 100%;
}
.dateInputBox label {
	display: block;
}
.TablesInputBox button {
	min-width: 100%;
}

.guestSelect,
.tableSelect {
	background-color: var(--secondaryBlack);
	border-color: var(--darkGrey);
	color: var(--white);
	font-size: 13px;
	line-height: 1.3;
	border-width: 2px;
	padding: 0.4em 1.4em 0.3em 0.6em;
}
.timeInput,
.dateInput {
	background-color: var(--secondaryBlack);
	border-color: var(--darkGrey);
	color: var(--white);
	font-size: 13px;
	line-height: 1.3;
	padding: 0em 0em 0.3em 0em;
	border-style: solid;
	border-width: 2px;
	text-indent: 0.3em;
	display: flex;
}

.AvailableTimeBox {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	background-color: var(--black);
	justify-content: center;
	align-items: center;
	padding: 4px 4px;
	padding-left: 0px;
    padding-right: 0px;
	gap: 10px;
	@media only screen and (max-height: 668px) and (min-height: 500px) {
		min-height: 19vh;
	}
	@media only screen and (max-height: 896px) and (min-height: 669px) {
		min-height: 15vh;
	}
}
.Time {
	&Box {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: normal;
		justify-content: space-between;
		max-width: 100%;
		min-width: 100%;
		.unavailable {
			width: 100%;
			flex: 100% 1;
			text-align: center;
			font-family: var(--fontFamily);
			font-size: 13px;
			line-height: 14.95px;
			color: var(--grey);
			text-transform: capitalize;
			font-weight: 400;
		}
	}
	&Button {
		background-color: var(--black);
		border-color: var(--white);
		margin-top: 8px;
		color: var(--white);
		border-style: solid;
		border-width: 1px;
		width: 49%;
		height: 32px;
	}
	&ButtonSelected {
		margin-top: 8px;
		border-width: 1px;
		background-color: var(--white);
		border-color: var(--white);
		border-style: solid;
		color: var(--black);
		width: 49%;
		height: 32px;
	}
}

.Confirm {
	&Box {
		min-width: 343px;
		max-width: 343px;
		position: fixed;
		bottom: 2%;
		justify-content: space-between;
		text-align: center;
		display: flex;
		left: 50%;
		transform: translateX(-50%);
		font-weight: 700;
	}
	&Button {
		background-color: var(--white);
		border-style: none;
		color: var(--black);
		height: 44px;
		font-size: 17px;
		font-weight: 700;
		line-height: 19.55px;
		width: 48%
	}

	&Disabled {
		border-style: none;
		color: var(--black);
		font-size: 17px;
		font-weight: 700;
		height: 44px;
		line-height: 19.55px;
		width: 48%;
		background: var(--tertiary);
		box-shadow: 0px 0px 1px rgba(117, 131, 142, 0.04), 0px 2px 4px rgba(52, 60, 68, 0.16);
	}
}

.Continue {
	&Button {
		background-color: var(--white);
		border-style: none;
		color: var(--black);
		height: 44px;
		font-size: 17px;
		font-weight: 700;
		line-height: 19.55px;
		width: 100%;
	}

	&Disabled {
		border-style: none;
		color: var(--black);
		font-size: 17px;
		font-weight: 700;
		height: 44px;
		line-height: 19.55px;
		width: 100%;
		background: var(--tertiary);
		box-shadow: 0px 0px 1px rgba(117, 131, 142, 0.04), 0px 2px 4px rgba(52, 60, 68, 0.16);
	}
}

.CancelButton {
	background: var(--black);
	border-style: solid;
	border-width: 1px;
	border-color: var(--white);
	opacity: 0.8;
	color: var(--white);
	height: 44px;
	font-size: 17px;
	line-height: 19.55px;
	width: 48%;
}

.ConfirmingButton {
	animation: countdown 2s linear forwards;
	background-color: var(--white);
	border-style: none;
	color: var(--black);
	font-size: 17px;
	font-weight: 700;
	height: 44px;
	line-height: 19.55px;
	width: 48%;
}

#partySizeSelect {
	background-color: var(--secondaryBlack);
	border-color: var(--darkGrey);
	color: var(--white);
	font-size: 13px;
	line-height: 1.3;
	padding: 2px;
	border-style: solid;
	border-width: 2px;
	text-indent: 0.3em;
	display: flex;
	width: 100%;
	height: 32px;
	justify-content: space-between;
}
#partyTimeSelect {
	background-color: var(--secondaryBlack);
	border-color: var(--darkGrey);
	color: var(--white);
	font-size: 13px;
	line-height: 1.3;
	padding: 0em 0em 0.3em 0em;
	border-style: solid;
	border-width: 2px;
	text-indent: 0.3em;
	display: flex;
	width: 100%;
	height: 32px;
	justify-content: space-between;
	padding: 2px;
}
.partySelectData {
	padding-left: 4px;
	padding-top: 4px;
	&.default {
		color: var(--grey);
	}
}

.TimeContainer {
	margin-top: 24px;
}


@keyframes countdown {
	0% {
		background: linear-gradient(90deg, var(--grey) 10%, rgba(147, 150, 150, 0) 100%), var(--white);
	}
	20% {
		counter-increment: my-count -0.5;
		background: linear-gradient(90deg, var(--grey) 30%, rgba(147, 150, 150, 0) 100%), var(--white);		
	}
	40% {
		counter-increment: my-count -0.75;
		background: linear-gradient(90deg, var(--grey) 50%, rgba(147, 150, 150, 0) 100%), var(--white);
	}
	60% {
		counter-increment: my-count -1;
		background: linear-gradient(90deg, var(--grey) 75%, rgba(147, 150, 150, 0) 100%), var(--white);
	}
	70% {
		counter-increment: my-count -1.25;
		background: linear-gradient(90deg, var(--grey) 80%, rgba(147, 150, 150, 0) 100%), var(--white);
	}
	80% {
		counter-increment: my-count -1.5;
		background: linear-gradient(90deg, var(--grey) 85%, rgba(147, 150, 150, 0) 100%), var(--white);
	}
	90% {
		counter-increment: my-count -1.75;
		background: linear-gradient(90deg, var(--grey) 90%, rgba(147, 150, 150, 0) 100%), var(--white);
	}
	100% {
		background: linear-gradient(90deg, var(--grey) 95%, rgba(147, 150, 150, 0) 100%), var(--white);
	}
}

.TimeBox .unavailable {
	font-weight: 500;
}
