.reserve {
	&Container {
		padding: 5%;
		background-color: var(--black);
		border-radius: 2% 2% 0 0;
		line-height: 35px;
		font-size: 15px;
		width: 100%;
		height: 100vh;
	}
	&Head {
		padding-bottom: 5%;
		display: flex;
		justify-content: space-between;
	}
	&Title {
		font-size: 17px;
		line-height: 19.55px;
		max-width: 60%;
		min-width: 60%;
	}
	&Close {
		line-height: 14.95px;
		text-align: left;
		font-size: 13px;
		max-width: 20%;
		min-width: 20%;
		padding: 0;
	}
}
