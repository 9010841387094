.cancel {
	&MainBox {
		position: absolute;
		padding: 0%;
		min-height: 100%;
		max-height: 100%;
		min-width: 100%;
		background-color: #796363;
		background: var(--black);
		top: 0%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	&Container {
		position: absolute;
		padding: 14px;
		min-height: 43vh;
		min-width: 100%;
		max-width: 375px;
		background-color: #796363;
		background: var(--black);
		line-height: 35px;
		font-size: 15px;
		bottom: 0%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	&Box {
		height: 40vh;
		min-width: 360px - 28px;
		background: var(--black);
		display: flex;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}
	&Description {
		min-height: 22vh;
		@media only screen and (max-height: 896px) and (min-height: 669px) {
			min-height: 25vh;
		}
		display: flex;
		text-align: center;
		justify-content: center;
		align-items: center;
	}
	&Title {
		font-weight: 400;
		font-size: 15px;
		line-height: 17px;
		margin-top: 5%;
		max-width: 70%;
	}
	&Corp {
		font-weight: 400;
		font-size: 15px;
		line-height: 17px;
		margin-top: 3%;
		color: var(--white);
	}
	&ButtonsBox {
		width: 343px;
	}
	&Button {
		width: 343px;		
		min-height: 44px;
		background: var(--black);
		border: none;
		font-weight: 400;
		font-size: 17px;
		line-height: 20px;
		color: var(--primary);
		border-color: var(--primary);
		border: solid 1px;
	}
	&BackButton {
		width: 343px;
		min-height: 44px;
		background-color: var(--white);
		color: var(--black);
		border: none;
		font-weight: 400;
		font-size: 17px;
		line-height: 20px;
		margin-top: 4%;
		font-weight: 700;
	}
}
