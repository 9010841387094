.noteBox {
	text-align: left;
	padding-top: 32px;
}
.notesTitle {
	font-size: 15px;
	line-height: 17.25px;
}
.notesDescription {
  margin-top: 12px;
  p {
    color: var(--grey);
    font-size: 15px;
    line-height: 18px;
    text-align: justify;
    padding-top: 0.23em;
    padding-bottom: 5px;
  }
}
