.reservation {
	&Container {
		position: absolute;
		padding: 0%;
		min-height: 100%;
		max-height: 100%;
		min-width: 100%;
		background-color: #796363;
		background: var(--black);
		top: 0%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	&StatusContainer {
		position: relative;
		min-height: 43vh;
		min-width: 100%;
		max-width: 375px;
	    background: var(--black);
		line-height: 35px;
		font-size: 15px;
		justify-content: center;
		text-align: center;
		align-items: center;
		display: flex;
		top: 57%;
		@media only screen and (max-height: 668px) and (min-height: 500px) {
			top: 57%;
		}
		padding-left: 16px;
		padding-right: 16px;
		padding-bottom: 12px;
		padding-top: 33px;
	}
}
