.reservation {
	&Container {
		position: absolute;
		padding: 14px;
		max-width: 100%;
		max-width: 375px;
		background: var(--black);
		line-height: 35px;
		font-size: 15px;
		bottom: 0%;
	}
	&Box {
		min-height: 35vh;
		min-width: 343px;
		background: var(--black);
		display: flex;
		text-align: center;
		flex-direction: column;
		align-items: center;
		@media only screen and (max-height: 668px) and (min-height: 500px) {
			justify-content: space-between;
		}
		@media only screen and (max-height: 896px) and (min-height: 669px) {
			justify-content: space-around;
		}
	}
	&Title {
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 17px;
		margin-top: 5%;
	}
	&Corp {
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 17px;
		margin-top: 3%;
		color: var(--grey);
	}
}
.done {
	&ButtonBox {
		min-width: 100%;
		max-width: 100%;
		margin-top: 7%;
	}
	&Button,
	&CanceledButton {
		min-width: 100%;
		max-width: 100%;
		min-height: 44px;
		border: none;
		font-style: normal;
		font-weight: 700;
		font-size: 17px;
		line-height: 20px;
		font-weight: 700;
	}
	&Button {
		background-color: var(--white);
		color: var(--black);
	}
	&CanceledButton {
		background-color: var(--white);
		color: var(--black);
	}
}
