
.PickerTime, .PickerGuests  {
    background-color: var(--black);
    bottom: 3%;
    font-family: sans-serif;
    text-align: center;
    margin: -5%;
    position: fixed;
    height: 280px;
    padding: 2% 0;
    min-width: 100%;
    z-index: 1;
    div {
        margin-bottom: 3%;
    }
}

.PickerTime p, .PickerGuests p {
    font-family: "Sofia Pro", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 23px !important;
    line-height: 28px;
    letter-spacing: 0.35px;
}

html {
    overflow: scroll;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
}
.PickerTime ul {
    padding: 0;
    width: 33%;
}
.PickerGuests ul {
    padding: 0;
    width: 50%;
}
.PickerGuests ul:focus, .PickerTime ul:focus{
    outline: none !important;
}
.PickerGuests ul:nth-child(1) li {
   justify-content: flex-end;
}
.PickerGuests ul:nth-child(2) li{
    justify-content: flex-start;
}

.PickerTime ul:nth-child(1) li {
    justify-content: flex-end;
 }
 .PickerTime ul:nth-child(2) li{
     justify-content: center;
 }
 .PickerTime ul:nth-child(3) li{
    justify-content: flex-start;
}

li span {
    display: none !important;
}

.pickerSelected {
    background-color: var(--grey);
    position: absolute;
    height: 20px;
    width: 200px;
}

[aria-selected="true"] {
    background-color: var(--secondaryBlack);
}

[aria-selected="false"] p {
    transform: scaleY(0.65);
}

.ConfirmBoxPicker {
    background: var(--black) !important;
    width: 100%;
    display: inline-block;
    bottom: 2%;
    z-index: 999;
}

.CancelButtonPicker {
    background: var(--black);
    border-style: solid;
    color: var(--white);
    min-width: 43%;
    height: 6vh;
    font-size: 17px;
    line-height: 19.55px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--white);
    font-weight: 700;
}

.ConfirmingButtonPicker {
    background-color: var(--white);
    border-style: solid;
    border-color: var(--white);
    color: var(--black);
    min-width: 43%;
    height: 6vh;
    font-size: 17px;
    line-height: 19.55px;
    margin-right: 2%;
    font-weight: 700;
}
