.venueBox {
	text-align: left;
	background-color: var(--black);
	min-height: 12%;
	max-height: 12%;
	margin-bottom: 2%
}

.venueInformation {
	min-width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.venueTitle {
	color: var(--white);
	font-size: 17px;
	line-height: 35px;
	letter-spacing: 7%;
	min-width: 100%;
	max-width: 100%;
}

.venueDescription {
	color: var(--grey);
	font-size: 15px;
	line-height: 21px;
}

.venueSecondarySection {
	display: flex;
	width: 100%;
	justify-content: space-between;
	.venueExplore {
		color: var(--white);
		background: transparent;
		border: none;
		text-decoration: none;
		font-size: 11px;
		display: inline-block;
		padding: 1px 6px;
	}
}
